@font-face {
    font-family: 'Platform Web';
    src: url('./fonts/platform-web/Platform-BoldItalic-Web.woff2') format('woff2'),
         url('./fonts/platform-web/Platform-BoldItalic-Web.woff') format('woff');
    font-weight:  700;
    font-style:   italic;
    font-stretch: normal;
}

@font-face {
    font-family: 'Platform Web';
    src: url('./fonts/platform-web/Platform-Bold-Web.woff2') format('woff2'),
         url('./fonts/platform-web/Platform-Bold-Web.woff') format('woff');
    font-weight:  700;
    font-style:   normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Platform Web';
    src: url('./fonts/platform-web/Platform-MediumItalic-Web.woff2') format('woff2'),
         url('./fonts/platform-web/Platform-MediumItalic-Web.woff') format('woff');
    font-weight:  500;
    font-style:   italic;
    font-stretch: normal;
}

@font-face {
    font-family: 'Platform Web';
    src: url('./fonts/platform-web/Platform-Medium-Web.woff2') format('woff2'),
         url('./fonts/platform-web/Platform-Medium-Web.woff') format('woff');
    font-weight:  500;
    font-style:   normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Platform Web';
    src: url('./fonts/platform-web/Platform-RegularItalic-Web.woff2') format('woff2'),
         url('./fonts/platform-web/Platform-RegularItalic-Web.woff') format('woff');
    font-weight:  400;
    font-style:   italic;
    font-stretch: normal;
}

@font-face {
    font-family: 'Platform Web';
    src: url('./fonts/platform-web/Platform-Regular-Web.woff2') format('woff2'),
         url('./fonts/platform-web/Platform-Regular-Web.woff') format('woff');
    font-weight:  400;
    font-style:   normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Platform Web';
    src: url('./fonts/platform-web/Platform-LightItalic-Web.woff2') format('woff2'),
         url('./fonts/platform-web/Platform-LightItalic-Web.woff') format('woff');
    font-weight:  300;
    font-style:   italic;
    font-stretch: normal;
}

@font-face {
    font-family: 'Platform Web';
    src: url('./fonts/platform-web/Platform-Light-Web.woff2') format('woff2'),
         url('./fonts/platform-web/Platform-Light-Web.woff') format('woff');
    font-weight:  300;
    font-style:   normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Platform Web';
    src: url('./fonts/platform-web/Platform-HairlineItalic-Web.woff2') format('woff2'),
         url('./fonts/platform-web/Platform-HairlineItalic-Web.woff') format('woff');
    font-weight:  100;
    font-style:   italic;
    font-stretch: normal;
}

@font-face {
    font-family: 'Platform Web';
    src: url('./fonts/platform-web/Platform-Hairline-Web.woff2') format('woff2'),
         url('./fonts/platform-web/Platform-Hairline-Web.woff') format('woff');
    font-weight:  100;
    font-style:   normal;
    font-stretch: normal;
}

@font-face {
	font-family: 'Formular';
	src: url('./fonts/formular-regular/formular-regular.eot');
	src:
	     url('./fonts/formular-regular/formular-regular.eot?#iefix') format('embedded-opentype'),
	     url('./fonts/formular-regular/formular-regular.woff2') format('woff2'),
	     url('./fonts/formular-regular/formular-regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
