.triangle-image {
    display: inline-block;
    width: 750px;
    height: 500px;
    min-width: 300px;
    filter: url('#goo');
}

@media (max-width: 900px) {
    .triangle-image.c-hidden {
        display: none;
    }
}

.triangle-image::before {
    content: "";
    display: block;
    padding-top: 86%;
    background: var(--b,orange);
    padding-top: 70%;
    clip-path: polygon(0% 0, 85% 35%, 0 100%);
  }

.about-us-header {
    margin-bottom: 10rem;
    position: absolute;
    right: 2rem;
}

.about-us-header::before {
    position: absolute;
    right: 0;
    height: 700px;
    width: 450px;
    clip-path: polygon(99% 0, 119% 108%, 0 70%);
}