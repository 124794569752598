@import url("./assets/css/fonts.css");
@import url("./assets/css/colors.css");

@tailwind base;

@layer base {
  h1 {
    @apply text-6xl;
    @apply mb-8;
  }
  h2 {
    @apply text-5xl;
    @apply mb-5;
  }
  h3 {
    @apply text-4xl;
    @apply mb-3;
  }
  h4 {
    @apply text-3xl;
    @apply mb-2;
  }
  span, p, a, div {
    @apply text-xl;
    @apply text-justify;
  }

  p {
    @apply mb-2;
  }
}

@tailwind components;
@tailwind utilities;


html {
  font-family: Formular,sans-serif;
  /* font-size: 21px; */
}

body {
  background-color: var(--color-breaktime-beige-100);
  font-family: Platform Web!important;
}

h1, h2, h3, h4, header {
  font-family: Platform Web,Formularsans-serif;
}
