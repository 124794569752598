.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 800px;
  position: relative;
  color: #1C7078;
}

.carousel-title {
  color: #FF746D;
  font-weight: bold;
  font-size: 3rem;
  position: absolute;
  right: 2rem;
  top: 1rem;
  line-height: 3rem;
  text-align: center;
}

@media (max-width: 600px) {
  .carousel-title {
    position: relative;
    right: 0;
  }
}

.cards-wrapper {
  overflow: hidden;
  padding: 2rem;
  width: 90%;
}

.cards {
  display: flex;
  transition: transform 0.3s ease;
}

.card {
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  padding: 1.25px;
  padding-top: 3rem;
  border-radius: 5px;
  transition: transform 0.3s ease;
  max-width: 15rem;
  position: relative;
}

.card img {
  width: 100%;
  height: 370px;
  object-fit: cover;
  border-radius: 3rem;
  filter: blur(1px) grayscale(50%);
}

.card.active img {
  filter: none;
  height: 420px;
}

.card p {
  margin-top: 10px;
  text-align: left;
  width: 400px;
  align-self: self-start;
}

@media (max-width: 800px) {
  .card p {
    width: auto;
  }
}

.card p.subtext {
  font-weight: 500;
}

.card:not(.active) {
  margin-top: 6.5rem;
}

.active {
  z-index: 1;
}

.prev-btn,
.next-btn {
  position: absolute;
  top: 20rem;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border: none;
  background-color: var(--color-deep-purple-100);
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

@media (max-width: 600px) {
  .prev-btn,
  .next-btn {
    top: 25rem;
  }
  .next-btn {
    right: -10px;
  }
}

.card-title {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.5rem;
}

.card-subtitle {
  margin-bottom: 1rem;
}

.button-container {
  position: absolute;
  bottom: -1rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

.button-action {
  color: white;
  background-color: #1C7078;
  padding: 0.5rem 1.25rem;
  border-radius: 2rem;
  cursor: pointer;
}