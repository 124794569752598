.swiper-button-next, .swiper-button-prev {
    color: var(--color-unique-coral-100);
}

.section-container {
    margin-left: 3rem;
    margin-right: 3rem;
}

@media (max-width: 600px) {
    .section-container {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}
