.who-we-are-content > h1 {
    font-weight: 800;
    flex: 1;
    font-size: 4rem;
    text-align: center;
}

.who-we-are-content > p {
    flex: 2;
}

@media (max-width: 600px) {
    .who-we-are-content {
        flex-direction: column;
    }

    .who-we-are-content > h1 {
        margin-bottom: 0.5rem;
    }
}