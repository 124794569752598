

body {
    --color-techy-green-100: rgba(28, 112, 120);
    --color-techy-green-80: rgba(28, 112, 120, 0.8);
    --color-techy-green-60: rgba(28, 112, 120, 0.6);
    --color-techy-green-40: rgba(28, 112, 120, 0.4);
    --color-techy-green-20: rgba(28, 112, 120, 0.2);

    --color-unique-coral-100: rgba(255, 116, 109);
    --color-unique-coral-80: rgba(255, 116, 109, 0.8);
    --color-unique-coral-20: rgba(255, 116, 109, 0.2);

    --color-worldwide-turquoise-100: rgba(177, 224, 228);
    --color-worldwide-turquoise-80: rgba(177, 224, 228, 0.8);
    --color-worldwide-turquoise-20: rgba(177, 224, 228, 0.2);

    --color-breaktime-beige-100: rgba(253, 243, 209);
    --color-breaktime-beige-80: rgba(253, 243, 209, 0.8);
    --color-breaktime-beige-20: rgba(253, 243, 209, 0.2);

    --color-agile-lavender-100: rgba(174, 168, 233);
    --color-agile-lavender-80: rgba(174, 168, 233, 0.8);
    --color-agile-lavender-20: rgba(174, 168, 233, 0.2);

    --color-agile-blue-100: rgba(229, 229, 229);
    --color-agile-blue-80: rgba(229, 229, 229, 0.8);
    --color-agile-blue-20: rgba(229, 229, 229, 0.2);
    
    --color-deep-purple-100: rgba(36, 40, 85);
    --color-deep-purple-80: rgba(36, 40, 85, 0.8);
    --color-deep-purple-20: rgba(36, 40, 85, 0.2);

    --color-terracota-100: rgb(255, 116, 109);
    --color-terracota-80: rgba(255, 116, 109, 0.8);
    --color-terracota-20: rgba(255, 116, 109, 0.2);

    --color-body: #B1E0E4;
    --color-main-text: #242855;
    --color-secondary-text: #847D66;
    --color-main-alt-text: #1C7078;
}
